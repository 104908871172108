.filterContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;

  .filters {
    display: flex;
    align-items: stretch;
    gap: 20px;

    .multiSelectFilter,
    .inputFilter {
      width: 360px;
    }
  }

  .searchBtn {
    width: 140px;
  }

  .showAll {
    padding-left: 20px;
  }
}
