.releaseNotesDialog {
  justify-content: flex-start;
  top: 50px;

  :global .k-dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

.markdownPreview {
  background: transparent;
  color: #656565;
  font-size: 14px;
}
