.dashGrid {
  cursor: pointer;
}

.buttonMarginTop {
  margin-top: 19px;
}

.buttonMarginLeft {
  margin-left: 30px;
}

.noValue {
  opacity: 30%;
}
