@import './variables.scss';
@import './custom.scss';
@import './drawer.scss';
@import '~@progress/kendo-theme-default/scss/all';

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
  // background-color: #F2F2F2 !important;
}

.footer {
  font-size: 14px;
  background: $white 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 1rem;
  padding-bottom: 15px;

  &.login {
    width: calc(100% + 30px);
    margin: -15px 0 0;
  }
}

.grid-heading {
  font-size: 1rem;
  color: $accent;
}

.blue-link {
  color: #007bff !important;
  cursor: pointer;
}

.k-toolbar {
  margin: 0;
  padding: 0;
}

.k-grid-toolbar {
  margin: 0;
  padding: 0;
}

.k-toolbar > * {
  margin-top: 0;
  margin-bottom: 0;
}

.aws-amplify-authenticator {
  --border-radius: 0px;
  --box-shadow: none;

  // --amplify-primary-color: $accent;
  #shadow-root .section {
    box-shadow: none !important;
  }
}

.companyLogoInfusion {
  max-width: calc(100% - 20px);
  max-height: calc(100% - 20px);
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  background-color: $accent;

  @media (max-width: 768px) {
    position: relative;
    height: 140px;
    width: auto;
    text-align: center;
    margin: 0 auto;
    left: 0;
    top: 0;
    padding: 10px;
  }
}

.insurance-card {
  #f1_container {
    position: relative;
    // margin: 10px auto;
    // width: 450px;
    // height: 281px;
    z-index: 1;
  }

  #f1_container {
    perspective: 1000;
  }

  #f1_card {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 1s linear;
  }

  #f1_container:hover #f1_card {
    transform: rotateY(180deg);
    box-shadow: -5px 5px 5px #aaa;
  }

  .face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  .face.back {
    display: block;
    transform: rotateY(180deg);
    box-sizing: border-box;
    padding: 10px;
    color: white;
    text-align: center;
    // background-color: #aaa;
  }
}

.tableText {
  font-size: 0.9rem;
  font-weight: 400;
  color: #93999e;
}

.header-details {
  margin-top: -86px;
  //margin-right: 0;
  //margin-left: 0;
  z-index: 100;
  font: roboto !important;
  background: linear-gradient(to right, #85c1f6 0%, $white 100%);
  color: $accent !important;

  .title {
    font-size: 0.8rem;
    font-weight: 300;
    color: $accent;
  }

  .headerText {
    font-size: 0.9rem;
    font-weight: 400;
    color: $accent;
  }

  .right-border {
    border-right: 1px solid $accent;
  }

  .notes {
    height: 30px;
    width: 140%;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  .pageButton {
    margin-top: 10px;
    margin-right: 5px;
  }

  .headerButtonBlue {
    margin-top: 10px;
    margin-right: 5px;
    color: $accent;
  }
}

.infusion-portal-header {
  margin-top: -66px;
  z-index: 96;
  font: roboto !important;
  background: linear-gradient(to right, #85c1f6 0%, $white 100%);
  color: $accent !important;
  background-color: $accent !important;

  .title {
    font-size: 0.8rem;
    font-weight: 300;
    color: $accent;
  }

  .headerText {
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0 0 0.3rem 1rem;
    color: $accent;
  }

  .right-border {
    border-right: 1px solid $accent;
  }

  .notes {
    height: 30px;
    width: 140%;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  .pageButton {
    margin-top: 10px;
    margin-right: 5px;
  }

  .headerButtonBlue {
    margin-top: 10px;
    margin-right: 5px;
    color: $accent;
  }
}

.patient-portal-header {
  // z-index: 100;
  font: roboto !important;
  background: linear-gradient(to right, #85c1f6 0%, $white 100%);
  color: $accent !important;

  .title {
    font-size: 0.8rem;
    font-weight: 300;
    color: $accent;
  }

  .headerText {
    font-size: 0.9rem;
    font-weight: 400;
    color: $accent;
  }

  .patientHeaderText {
    font-size: 0.8rem;
    font-weight: 600;
    color: $accent;
    text-overflow: clip;
  }

  .patientFieldName {
    font-weight: 400;
    font-size: 0.6rem;
    padding: 0 0 0.3rem 1rem;
    color: #343a40;
    border-top: 0px solid silver;
  }

  .right-border {
    border-right: 1px solid $accent;

    @media (max-width: 999px) {
      border-right: none;
    }
  }

  .notes {
    height: 30px;
    width: 140%;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  .pageButton {
    margin-top: 10px;
    margin-right: 5px;
  }

  .headerButtonBlue {
    margin-top: 10px;
    margin-right: 5px;
    color: $accent;
  }
}

.infusion-page {
  margin-left: 10px;
  //margin-right: 0;
  //margin-left: 0;
  //z-index: 100;
  margin-bottom: 50px;
  min-height: 550px;
}

.infusion-questions {
  .category {
    font-size: 14px;
    font-weight: 400;
    color: $accent;
    margin-bottom: 5px;
  }

  .question {
    font-size: 12px;
    font-weight: 500;
    color: rgb(122, 122, 122);
  }

  .answer {
    font-size: 14px;
    font-weight: 600;
    color: rgb(70, 70, 70);
    margin-bottom: 10px;
  }
}

.agent-title {
  color: #2aa0fd !important;
  font-size: 1.3rem;
}

.k-i-logout {
  position: absolute;
  color: #2aa0fd !important;
  cursor: pointer;
  right: 20px;
  top: 25px;

  &.portal {
    top: 0px;
  }
}

.k-icon-32 {
  font-size: 32px;
  /* Sets icon size to 32px */
}

.k-icon-48 {
  font-size: 48px;
  /* Sets icon size to 48px */
}

.k-icon-64 {
  font-size: 64px;
  /* Sets icon size to 64px */
}

.patient-document-image {
  border: 1px solid $accent;
}

.k-dialog {
  max-height: calc(100% - 50px);
}

.patient-form .k-card {
  // background-color: #F0F8FD;
  height: 100%;
}

.patient-document .k-card {
  background-color: #f0f8fd;
  border-color: $accent;
}

ul.document-pagination {
  margin-top: 15px;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

ul.document-pagination li.page-item.active a.page-link {
  color: #445565 !important;
  background-color: #e3e7eb !important;
  border-color: #ced4da !important;
}

ul.document-pagination a.page-link {
  padding: 0.55rem 0.55rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #6b88a4;
  font-weight: 900;
  font-size: 1rem;
}

ul.document-pagination a.page-link:hover {
  background-color: #f4f4f4;
}

.document-pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: center;
}

.react-pdf__Page__annotations {
  display: none !important;
}

.doc-fixed-right {
  position: fixed;
  top: 220px;
  right: -45px;
  transform: rotate(90deg);
  border-radius: 0px 0px 10px 10px !important;
  border-color: $accent;
  color: #f9f9f9;
  background-color: #0f4022;
  background-image: none;
}

.k-window {
  z-index: 98 !important;
}

.k-overlay {
  z-index: 95 !important;
}

.content {
  background-color: white !important;
  //   margin-bottom: -51px;
  // width: 100vw;
}

.agent-work-queue {
  .k-card {
    position: unset !important;
  }
}

#clinicalNote,
textarea {
  width: 100% !important;
}

.header {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K');
}

.branding {
  position: relative;
  margin-bottom: var(--margin-bottom);
  padding: 15px;
  border-radius: var(--border-radius);
  box-shadow: 4px 4px 4px 0 rgba(119, 108, 108, 0.15);
  box-sizing: border-box;
  background-color: white;
  margin-top: -15px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 469px;
  min-width: 450px;

  //aliceblue  $accent   #d3e9ff
  .branding__logo {
    width: 100%;
    flex: 1;
    padding: 35px 30px 0 30px;
  }
}

.loggedin_container {
  display: block;
  margin: 0;
  padding: 0;
}

.sign-in-bg {
  // background-image: url("assets/images/login_bg.jpeg");
  background-repeat: no-repeat;
}

.large-btn {
  font-size: 25px;
  padding: 5px 25px;
}

.fax-form-assignment {
  background-color: #f0f8fd;
  border: 1px solid $accent;
  border-radius: 15px;
}

.inbound-existing-patient {
  .k-header input[type='checkbox'] {
    display: none;
  }
}

.large-btn-width {
  padding: 5px 25px;
}

.patient-tab {
  .k-tabstrip-items {
    .k-link {
      padding: 12px 26px;
    }
  }
}

.a-grid__header {
  background-color: $accent;
  color: white;

  div {
    width: 300px;
    background-color: #4696e7;
    padding: 9px;
  }
}

.a-grid {
  .k-grid-header {
    color: $accent;
    background-color: #e8e8e8;
  }

  td {
    border: 0px !important;
    border-bottom: 1px solid #afaaaa !important;
  }

  th.k-header {
    border-color: transparent !important;
  }

  tr.k-alt {
    background-color: $white !important;
  }
}

.nurse-queue_grid {
  .k-grid-header {
    color: $accent;
    background-color: #e8e8e8;
  }

  td {
    border: 0px !important;
    border-bottom: 1px solid #afaaaa !important;
    height: 50px !important;
  }

  th.k-header {
    border-color: transparent !important;
  }

  // tr.k-alt {
  //     background-color: $white !important;
  // }
}

.queue_grid {
  .k-grid-header {
    color: $accent;
    background-color: #e8e8e8;
  }

  /* 
    td  {
        border: 0px !important;
        border-bottom: 1px solid #afaaaa !important;
       // height: 50px !important;
    }
    th.k-header  {
        border-color: transparent !important;
    }
    // tr.k-alt {
    //     background-color: $white !important;
    // } */
}

.k-avatar {
  border-color: $accent;
  color: $white;
  background-color: $accent;
}

.k-avatar-secondary {
  border-color: #666666;
  background-color: #666666;
}

.k-avatar-tertiary {
  border-color: #03a9f4;
  background-color: #03a9f4;
}

.k-avatar-info {
  border-color: #0058e9;
  background-color: #0058e9;
}

.k-avatar-success {
  border-color: #37b400;
  background-color: #37b400;
}

.k-avatar-warning {
  border-color: #ffc000;
  background-color: #ffc000;
}

.k-avatar-error {
  border-color: #f31700;
  background-color: #f31700;
}

.k-avatar-dark {
  border-color: #424242;
  background-color: #424242;
}

.k-avatar-light {
  border-color: #ebebeb;
  background-color: #ebebeb;
}

.k-i-information {
  color: $white !important;
  right: 5px;
}

.k-i-comment {
  color: $white !important;
  right: 5px;
}

.k-i-document-manager {
  color: $white !important;
  right: 5px;
}

.k-i-copy {
  color: $white !important;
  right: 5px;
}

.k-i-pencil {
  color: $white !important;
  right: 5px;
}

.k-i-print {
  color: $white !important;
  right: 5px;
}

.k-i-track-changes {
  color: $white !important;
  right: 5px;
}

.k-icon-29 {
  font-size: 29px;
}

.k-panel {
  padding: 0px 20px 20px !important;
  background-color: #f0f8fd !important;
}

.k-content {
  background-color: #f0f8fd !important;
}

/* MM */
.k-form {
  margin: 0px;
}

.k-tabstrip > .k-content {
  padding: 10px 0 0 10px;
  border-width: 0;
}

.k-tabstrip-top > .k-tabstrip-items {
  border-bottom-width: 1px;
  flex-wrap: wrap;
}

.mt-02 {
  margin-top: 0.2rem;
}

.mt-04 {
  margin-top: 0.4rem;
}

.mt-06 {
  margin-top: 0.6rem;
}

.mt-08 {
  margin-top: 0.8rem;
}

.mt-10 {
  margin-top: 1rem;
}

.mt-12 {
  margin-top: 1.2rem;
}

.mt-14 {
  margin-top: 1.4rem;
}

.mt-16 {
  margin-top: 1.6rem;
}

.mt-18 {
  margin-top: 1.8rem;
}

.mt-20 {
  margin-top: 2rem;
}

.mt-22 {
  margin-top: 2.2rem;
}

.mt-24 {
  margin-top: 2.4rem;
}

.k-i-logout.portal {
  z-index: 999;
}

.important-text {
  font-weight: bold;
}

.header-details-field-name {
  font-weight: 400;
  font-size: 0.6rem;
  padding: 0 0 0.3rem 1rem;
  color: #343a40;
  border-top: 0px solid silver;
}

.patient-search {
  background-color: #f0f8fd;
  border: 1px solid #eeeeee;
  border-radius: 8px;
}

.k-card-body {
  padding: 8px;
}

// [nn]
.infusion-details {
  font: roboto !important;
  background: $white;
  color: $accent !important;

  .title {
    font-size: 0.8rem;
    font-weight: 300;
    color: $accent;
  }

  .headerText {
    font-size: 0.9rem;
    font-weight: 400;
    color: $accent;
  }

  .notes {
    height: 30px;
    width: 140%;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  .pageButton {
    margin-top: 10px;
    margin-right: 5px;
  }

  .headerButtonBlue {
    margin-top: 10px;
    margin-right: 5px;
    color: $accent;
  }
}

.infusion-HeaderRow {
  font: roboto !important;
  background: $accent;
  color: $white !important;

  .title {
    font-size: 0.8rem;
    font-weight: 300;
    color: $accent;
  }

  .headerText {
    font-size: 0.9rem;
    font-weight: 400;
    color: $white;
  }
}

.infusion-SubHeaderRow {
  font: roboto !important;
  background: $white;
  color: $accent !important;

  .title {
    font-size: 0.8rem;
    font-weight: 300;
    color: $accent;
  }

  .headerText {
    font-size: 0.9rem;
    font-weight: 400;
    color: $white;
  }
}

.infusion-SubHeaderRowGrey {
  font: roboto !important;
  background: #d9d9d9;
  color: $white !important;

  .title {
    font-size: 0.8rem;
    font-weight: 300;
    color: $accent;
  }

  .headerText {
    font-size: 0.7rem;
    font-weight: 500;
    color: $accent;
    margin-left: 5px;
  }
}

.infusion-details-field-name {
  font-weight: 400;
  font-size: 0.6rem;
  padding: 0 0 0.3rem 1rem;
  color: #343a40;
}

.infusion-border {
  border: 1px solid #afaaaa;
}

.infusion-grid {
  .k-grid-header {
    color: $accent;
    background-color: #e8e8e8;
    font-weight: 400;
    font-size: 0.6rem;
    // padding: 0 0 0.3rem 1rem;
  }

  td {
    border: 0px !important;
    border-bottom: 1px solid #afaaaa !important;
  }

  th.k-header {
    border-color: transparent !important;
  }

  tr.k-alt {
    background-color: $white !important;
  }
}

// [SY]
.k-i-logout.infusionqueue {
  z-index: 999;
}

.grid-theme-heading {
  height: 40px;
  background-color: $accent;
  margin: 0px;
  color: $white;
}

.patient-portal {
  .k-tabstrip-items {
    background-color: $accent;

    .k-item {
      background-color: $accent;
      padding: 0 0px;
      font-weight: 700;
      font: Roboto !important;
      font-size: 14px;
    }
  }

  .k-tabstrip > .k-content {
    padding: 0px;
  }

  .k-tabstrip-items .k-item:hover,
  .k-tabstrip-items .k-item.k-state-hover {
    color: $white;
  }

  .k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
    background-color: $white;
    color: gray;
  }

  .k-tabstrip-top > .k-tabstrip-items > .k-item {
    height: 35px;
  }

  .bottom-tabstrip-container {
    .k-tabstrip-items .k-item {
      font-weight: 600;
      padding: 0 25px;
      font-size: 0.9rem;
      color: $white;
      border-right: 1px solid #e3e7eb;
      height: 35px;
    }

    .k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
      background-color: $accent;
      font-size: 0.9rem;
      color: #ff9900;
    }
  }
}

// TSG - Tab Strip Gray
#tab-strip-gray {
  .tsg-container {
    .k-tabstrip-items {
      background-color: transparent;
      height: 45px;

      .k-item {
        background-color: transparent;
        border: 0;
        color: $gray-600;
        font: Roboto !important;
        font-weight: 700;
        font-size: 14px;
        padding: 0 20px;
      }

      .k-item.k-state-active {
        background-color: $gray-400;
        border: 1px solid $gray-500;
        color: $accent;
      }

      .k-item:hover,
      .k-item.k-state-hover {
        color: $keshri;
      }
    }
  }
}

.inventory-portal {
  .k-tabstrip-items {
    background-color: $accent;

    .k-item {
      background-color: $accent;
      padding: 0 65px;
      font-weight: 600;
      font: Roboto !important;
      font-size: 1.1rem;
      height: 35px;
    }
  }

  .k-tabstrip > .k-content {
    padding: 0px;
  }

  .k-tabstrip-items .k-item:hover,
  .k-tabstrip-items .k-item.k-state-hover {
    color: $white;
  }

  .k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
    background-color: $white;
    color: gray;
  }

  .second-tabstrip-container {
    .k-tabstrip-items .k-item {
      font-weight: 600;
      font-size: 0.9rem;
      color: $white;
      border-right: 1px solid white;
      height: 35px;
    }

    .k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
      background-color: $accent;
      font-size: 0.9rem;
      color: #ff9900;
    }
  }
}

.k-scheduler-toolbar {
  z-index: auto;
}

.k-dialog-wrapper {
  z-index: 100 !important;
}

.infusion-header {
  background-color: $accent;
}

.k-form-red .k-dialog-titlebar {
  background-color: #f26363 !important;
}

// Infusion Stepper
.k-icon-16 {
  font-size: 16px;
  /* Sets icon size to 16px */
}

.k-step-link .k-icon {
  font-size: 24px;
  color: #000;
}

.k-step-link .step-label {
  color: $accent;
  font-weight: bold;
  background-color: $accent;
}

.text-blue {
  color: $accent;
}

.text-orange {
  color: $keshri;
}

.k-tabstrip-items .k-link {
  padding: 12px 20px;
}

.labelstyle {
  font-size: 15px;
  font-weight: 700;
  color: #000;
  margin-left: 40px;
}

.panelInfusion {
  font-size: 15px;
  font-weight: 700;
  color: #000;

  .panelInfusionInner {
    background-color: #ffffff;
    font-weight: 400;
    font-size: 12px;
  }
}

.infusionTable {
  .table {
    border: 1px solid #dddddd;
    border-collapse: collapse;
    width: 100%;
  }

  .th {
    text-align: left !important;
    padding: 8px;
    font-size: 14px;
    background-color: #e2e1e1 !important;
  }

  .td {
    text-align: left !important;
    padding: 8px;
  }
}

.benfit-history-tab {
  .k-animation-container {
    height: 100vh;
    z-index: 10003 !important;
  }
}

.full-page-loader {
  .k-window {
    .k-content {
      background-color: transparent !important;
    }

    background-color: transparent;
    box-shadow: none;
  }
}

// .benfit-invest-tabs {
//     .k-animation-container {
//         height: 100vh
//     }
// }
.margin_left {
  margin-left: 120px;
}

.margin_top {
  margin-top: -45px;
}

.check-notes {
  .k-panelbar {
    .k-animation-container {
      z-index: 0;
    }
  }
}

///Billing History

.billing-spacing {
  margin-top: 7px;
}

.billing-style {
  font-size: 14;
  font-weight: bold;
}

/// Kendo Skeleton
.k-skeleton {
  display: block;
}

.k-skeleton-text {
  border-radius: 2px;
  -ms-transform: scale(1, 0.6);
  transform: scale(1, 0.6);
}

.k-skeleton-text:empty::before {
  content: '\00a0';
}

.k-skeleton-rect {
  border-radius: 0;
}

.k-skeleton-circle {
  border-radius: 9999px;
}

@keyframes k-skeleton-wave {
  0% {
    transform: translateX(-100%);
  }

  60% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.k-skeleton-wave .k-skeleton,
.k-skeleton-wave.k-skeleton {
  position: relative;
  overflow: hidden;
}

.k-skeleton-wave .k-skeleton::after,
.k-skeleton-wave.k-skeleton::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  animation: k-skeleton-wave 1.6s linear 0.5s infinite;
}

@keyframes k-skeleton-pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.k-skeleton-pulse .k-skeleton,
.k-skeleton-pulse.k-skeleton {
  animation: k-skeleton-pulse 1.5s ease-in-out 0.5s infinite;
}

.k-skeleton {
  background-color: rgba(66, 66, 66, 0.2);
}

.k-skeleton-wave .k-skeleton::after,
.k-skeleton-wave.k-skeleton::after {
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(0, 0, 0, 0.04),
    transparent
  );
}

.popup-content {
  padding: 30px;
  color: #787878;
  background-color: #fcf7f8;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.popup-content-all-day {
  max-width: 400px;
  padding: 10px;
  color: #5a5a5a;
  background-color: #f9efd2;
  border: 1px solid #e8be4d;
  font-size: 12px;
}

.k-notification {
  font-size: 16px;
  padding: 10px;
}

.white-label {
  color: white !important;
}

.dark-label {
  color: $accent !important;
}

.k-badge-edge {
  position: absolute;
  z-index: 99;
}

.aic-badge {
  border-radius: 5px;
  font-weight: 500;
  margin-right: 5px;
  padding: 2px 5px;
  white-space: nowrap;
}

.k-notification-group {
  z-index: 999;
}

.bright {
  color: $accent;
}

.help-link {
  color: $accent;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.blue-label {
  color: $accent;
  cursor: pointer;
  text-decoration: none;
}

.k-calendar-container {
  z-index: 10101 !important;
}

.icon-blue {
  color: #005282;
}

.location-time-label,
.gray-hint {
  color: #ccc !important;
  font-size: 10px;
}

.icd10-box:not(:first-of-type) {
  margin-top: 15px;
}

.remove-label {
  color: darkred;
  cursor: pointer;
}

.green {
  color: green;
}

.f10px {
  font-size: 10px;
}

.f12px {
  font-size: 12px;
}

.dotted-link {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.label-icon {
  padding-right: 7px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.survey-header-table {
  table-layout: fixed;
  width: 100%;
}

.survey-header-table td {
  padding: 5px 0;
}

/* CSS */
.custom-file-upload {
  align-items: center;
  appearance: button;
  background-color: #4696e7;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: 'Segoe UI', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 100%;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  padding: 4px 4px;
  padding-left: 16px;
  text-align: center;
  text-transform: none;
  transition: color 0.13s ease-in-out, background 0.13s ease-in-out,
    opacity 0.13s ease-in-out, box-shadow 0.13s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.custom-file-upload:active {
  background-color: #006ae8;
}

.custom-file-upload:hover {
  background-color: #e2e1e1;
  color: rgb(37, 37, 37);
}

.call-grid {
  .k-grid-header col:nth-of-type(1) {
    width: 23%;
  }

  .k-grid-table col:nth-of-type(1) {
    width: 23%;
  }

  .k-grid-header col:nth-of-type(2) {
    width: 20%;
  }

  .k-grid-table col:nth-of-type(2) {
    width: 20%;
  }

  .k-grid-header col:nth-of-type(3) {
    width: 20%;
  }

  .k-grid-table col:nth-of-type(3) {
    width: 20%;
  }

  .k-grid-header col:nth-of-type(4) {
    width: 45%;
  }

  .k-grid-table col:nth-of-type(4) {
    width: 45%;
  }

  .k-grid-header col:nth-of-type(5) {
    width: 45%;
  }

  .k-grid-table col:nth-of-type(5) {
    width: 45%;
  }

  .k-grid-header col:nth-of-type(6) {
    width: 20%;
  }

  .k-grid-table col:nth-of-type(6) {
    width: 20%;
  }

  .k-grid-header col:nth-of-type(7) {
    width: 20%;
  }

  .k-grid-table col:nth-of-type(7) {
    width: 20%;
  }

  .k-grid-header col:nth-of-type(8) {
    width: 20%;
  }

  .k-grid-table col:nth-of-type(8) {
    width: 20%;
  }

  .k-grid-header col:nth-of-type(9) {
    width: 15%;
  }

  .k-grid-table col:nth-of-type(9) {
    width: 15%;
  }

  .k-grid-header col:nth-of-type(10) {
    width: 15%;
  }

  .k-grid-table col:nth-of-type(10) {
    width: 15%;
  }
}

.cell_clickable {
  cursor: pointer;

  > * {
    cursor: pointer;
  }
}

hr.red {
  border-top: 1px solid red;
}
