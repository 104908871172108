.dateRangePicker {
  display: flex;
  margin: 20px 0;

  :global .k-daterangepicker-wrap {
    width: 400px !important;
    display: flex !important;
    align-items: center;
    gap: 4px;

    .k-textbox-container {
      margin: 0 !important;
    }
  }
}

:global .k-pdf-export {
  td.print {
    span.title {
      padding-right: 20px;
    }
  }
}
