.k-popup-dropdowntree.aic-location {
  max-height: 300px;
  overflow-y: scroll;
  // scroll-padding: 0 !important;
  // scrollbar-gutter: 0;

  .k-content {
    background-color: transparent !important;
    // border: 1px solid red;
    // margin: 0;
    // padding: 0;
  }
}

.k-dropdowntree.aic-location {
  .k-dropdown-wrap .k-clear-value {
    display: none !important;
  }
}

.k-grid-footer {
  color: #656565 !important;
  background-color: transparent !important;
}

.k-grid-header,
.k-grid-footer {
  padding: 0 !important;
}
