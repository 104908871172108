$main-header-height: 85px;
$grid-columns: 12;
$grid-gutter-width: 48px;
$grid-column-width: 48px;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
);

.card {
    background-color: $grid-alt-bg;
    border-color: $grid-header-bg;

    &.card-radius-12 {
        border-radius: 12px;
    }
}

button {
    color: $grid-header-bg;
    border-radius: 5px !important;
    background-color: #ffffff;

    &.blue {
        background-color: $grid-header-bg;
        color: $grid-bg;
        background-image: none;
    }

    &.pageButtonBlue {
        background-color: #4696e7;
        color: $grid-bg;
        background-image: none;
    }

    &.red {
        background-color: #de4952;
        border: #c94747;
        color: $grid-bg;
        background-image: none;
    }

    &.orange {
        background-color: #ffc27f;
        border: #f26363;
        color: $grid-bg;
        background-image: none;
    }

    &.orangePending {
        background-color: #ff9900;
        border: #f26363;
        color: $grid-bg;
        background-image: none;
    }

    &.pageButton {
        background-color: #4696e7;
        color: #ffffff;
        font-weight: 500;
        background-image: none;
        border: #4696e7;
        width: 130px !important;
        height: 30px;
    }

    &.snoozeButton {
        background-color: #4696e7;
        color: #ffffff;
        font-weight: 500;
        background-image: none;
        border: #4696e7;
        width: 145px !important;
        height: 30px;
    }

    &.labDrawButton {
        background-color: #4696e7;
        color: #ffffff;
        font-weight: 500;
        background-image: none;
        border: #4696e7;
        width: 140px !important;
        height: 30px;
    }

    &.clearButton {
        background-color: #4696e7;
        color: #ffffff;
        font-weight: 500;
        background-image: none;
        border: #4696e7;
        width: 60px !important;
        height: 30px;
    }

    &.pageTab {
        background-color: #005282;
        color: #ffffff;
    }

    &.gridButton {
        border-radius: 0px !important;
        background-color: #ffffff !important;
        font-weight: 500;
        color: #005282 !important;
    }

    &.active {
        background-color: #4696e7 !important;
        color: #ffffff !important;
        font-weight: 500;
        background-image: none;
        border: #4696e7;
        width: 130px !important;
        height: 30px;
    }

    &.headerButton2 {
        background-color: #005282 !important;
        color: #ffffff !important;
        font-weight: 500;
        background-image: none;
        border: #005282 !important;
        width: 130px !important;
        height: 40px;
        margin-right: 5px;
    }

    &.headerButton1 {
        background-color: #4696e7;
        color: #ffffff;
        font-weight: 500;
        background-image: none;
        border: #4696e7;
        width: 130px !important;
        height: 40px;
    }

    &.withPadding {
        padding: 5px 15px;
    }
}

// hide the today button on the scheduler
// it goes to yesterday when the timezone for scheduler is different than current user's timezone
.k-scheduler {
    button[title='Today'] {
        display: none;
    }

    .k-event-template {
        height: 100%;
    }
}

// main tab strip
$tabstrip-bg: #ffffff;
//$tabstrip-text: $gray-500;
$tabstrip-item-text: $gray-850;
$tabstrip-item-bg: #ffffff;
$tabstrip-item-padding-y: 12px;
$tabstrip-item-selected-bg: #005282;
$tabstrip-item-selected-text: #ffffff;
$tabstrip-content-bg: #f0f8fd;
$tabstrip-item-border-radius: 0px;

.navBar1 {
    font: SansSerif !important;
    font-weight: 800;
    padding: 0;
    margin: 0;
}

.navBar2 {
    //font-weight: 900 !important;
    background-color: #005282;
    padding: 0px 5px;
    font: SansSerif !important;
}

.tabText {
    font-weight: 400;
    font: Roboto !important;
}

.pageTitle {
    color: #ff9900;
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
    //margin-left: 10px;
}

h3,
h2,
h1,
h5,
h6 {
    color: $grid-header-bg;
    font-weight: 500;
    font: roboto !important;

    &.pageTitle {
        color: #ff9900;
        font-weight: 600;
        font-size: large;
    }

    &.pageSubHead {
        font-weight: 600;
        font-size: medium;
    }
}

h4 {
    color: $grid-header-bg;
    font: roboto !important;
    font-weight: 600;
    font-size: medium;
}

/* Kendo Switch 
.k-widget {
    font-size: 14px !important;
}*/

.k-grid-header {
    padding: 3px 0;
}

.k-radio.blue {
    color: blue;
}

.k-radio.patient-radio {
    background: white;
    border-color: grey;
    box-shadow: none;
}

/*
   a. Define All usefulld dependencies
   b. 
*/

.k-tabstrip {
    .k-animation-container-relative {
        width: 100%;
    }
}

.dialog-Fax {
    border-color: inherit;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f0f8fd !important;
}

.k-dialog-titlebar {
    border-color: inherit;
    color: #ffffff;
    background-color: #4696e7 !important;
}

.k-icon {
    color: #005282 !important;
}

.k-iconOrange {
    color: #f58142 !important;
}

.k-iconWhite {
    color: #ffffff !important;
}

.patient-content {
    .k-content {
        padding: 6px 0px !important;
    }
}

.portal-container {
    height: 100vh;
}

.flex-container {
    display: flex;
}

.side-bar {
    background-color: $accent;
    color: white !important;
    min-width: 250px;
    min-height: 1100px;
    z-index: 98;
}

@media (min-width: 600px) {
    .side-bar {
        max-width: 250px;
    }
}

.main-content {
    min-height: 0;
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-basis: 0;
    flex-direction: column;
}

.error-message {
    display: flex !important;
    justify-content: center !important;
}

.work-in-progress .k-master-row td {
    height: 30px;
}

.dialog-script {
    background-color: #ffffff;
}

.notesWrapper {
    white-space: break-spaces;
}

.button-block {
    min-width: 100%;
    width: 100%;
}