@import '../../styles/variables.scss';

.root {
  width: 100%;
  padding: 0;
  margin: 0 auto;
}

.appHeader {
  background-color: #005282;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 65px;
}

.appPortalHeader {
  min-height: 85px;
}

.logoContainer {
  color: aliceblue;
  font-weight: normal;
  font-size: 1.3rem;
  display: flex;
}

.logo {
  position: absolute;
  left: 0px;
  cursor: pointer;
  background-color: $accent;
}

.companyLogo {
  height: 35px;
  margin-left: 20px;
  margin-top: 15px;
}

.portalLogo {
  height: 65px;
  margin-left: 3.5rem;
}

.projectContainer {
  display: flex;
  justify-content: center;
  align-self: center;
}

.projectName {
  font-weight: 400;
  font-size: 1.2rem;
  color: $white;
}

.username {
  color: #4cc0e2;
  font-weight: 500;
  position: absolute;
  top: 20px;
  right: 80px;
  z-index: 999;
}

.icon {
  font-size: 20px;
  margin-right: 5px;
}

.hamburgerIcon {
  background-image: url('../../assets/images/hamburger-icon.svg');
  background-repeat: no-repeat;
  width: 40px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 25px;
  filter: invert(68%) sepia(17%) saturate(3690%) hue-rotate(167deg)
    brightness(109%) contrast(77%);
  cursor: pointer;
  z-index: 97;
}

.hamburgerMenu {
  width: 160px;
  position: absolute;
  top: 65px;
  right: 20px;
  padding: 10px 15px;
  background-color: #eeeeee;
  z-index: 97;

  .row {
    border-bottom: 1px solid #cccccc;
    cursor: pointer;
  }
}
