.hrSpacing {
    margin: 0.5rem 0
}

.labelPadding {
    padding: 0 15px
}

.fieldWidth {
    width: 100%
}