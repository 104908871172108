// Used Controls:["grid"]
$base-theme: Default;
$skin-name: grid;
$accent: #005282;
$light-blue: #4696e7;
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$gray-850: #93999e !default;

$grid-bg: #ffffff;
$grid-alt-bg: #f0f8fd;
$grid-header-bg: $accent;
$grid-header-text: #ffffff;
$button-border: $grid-header-bg;
$keshri: #d8951b;

$button-hover-bg: #2aa0fd;
