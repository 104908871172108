.infusionInfo,
.generalInfo {
  position: absolute;
  top: 20px;
  right: 80px;
  font-weight: 500;
  color: #4cc0e2;
  z-index: 97;
}

.infusionInfo {
  margin-left: 30px;
}

.date {
  margin-right: 30px;
}

.userIcon {
  margin-right: 10px;
  margin-bottom: 3px;
}
