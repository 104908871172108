.card {
  width: 350px;
  background-color: #f0f8fd !important;
}

.description {
  color: darkred;
  font-style: bold;
  font-size: 15px;
}

.actions {
  justify-content: end !important;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
