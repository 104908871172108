.banner {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: #eae682;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #d3cf75;
    transition: 0.4s;
  }
}

.sticky {
  background-color: #666;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .column1 {
    flex: 0 1 250px;
    color: wheat;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    margin-right: 15px;
    justify-content: center;

    .nextItem {
      padding-top: 15px;
    }
  }

  .column2 {
    flex: 1 1 800px;
    background-color: #8f8e8e;
    border-radius: 8px;
    padding: 10px;
  }

  .column3 {
    flex: 0 1 180px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-left: 15px;
  }
}

.miniContainer {
  display: flex;
  align-items: center;
  color: wheat;
  font-size: 14px;
}

.miniContainer > * {
  margin: 0 12px; /* Half of the desired gap */
}

.stickyMini {
  background-color: #666;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  display: block;
  position: absolute;
  color: wheat;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  right: 20px;
}
