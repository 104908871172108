.actions {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.actionButton {
    background-color: #005282;
    color: white;
    height: 35px;
    border: 0;
    padding: 0 15px;


    &:disabled {
        background-color: #666;
        cursor: not-allowed;
    }
}

.modalBody {
    font-size: 16px;
    padding-bottom: 20px;
}

.minButtonWidth {
    min-width: 150px;
}

.category {
    margin-top: 15px;
    font-weight: bold;

    span.k-badge-container {
        display: block !important;
    }
}