@import '../styles/variables.scss';

.hr {
  background-color: #1f7eb4 !important;
  margin: 0.75rem;
  margin-right: 0.5rem;
}

.title {
  background-color: #8cbeec !important;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-content: center !important;
}

.colored-icon {
  color: green;
}

.category {
  margin-top: 15px;
  font-weight: bold;

  span.k-badge-container {
    display: block !important;
  }
}

.status {
  width: 4px;
  margin-right: 7px;
}

.categoryItem {
  display: flex;
  margin-left: 1.6rem;
  padding-right: 1rem;
  padding-left: 0;
}

.categoryChip {
  display: flex;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}

.followUpButton {
  background-color: #2aa0fd !important;
}

.orange {
  background-color: orange;
}
.green {
  background-color: #2ed22e;
}
.red {
  background-color: red;
}
.yellow {
  background-color: yellow;
}

/* status bar header */
.logo {
  cursor: pointer;
}

.companyLogo {
  height: 65px;
  margin-left: 0.4rem;
}

.portalLogo {
  height: 35px;
  cursor: pointer;
}

.root {
  width: 100%;
  padding: 0;
  margin: 0 auto;
}

.appHeader {
  background-color: #005282;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 65px;
}

.appPortalHeader {
  min-height: 85px;
}

.logoContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
}

.notesContainer {
  text-align: center;
  padding-top: 15px;
}

.projectContainer {
  display: flex;
  justify-content: center;
  align-self: center;
}

.projectName {
  font-weight: 400;
  font-size: 1.2rem;
  color: $white;
}

.username {
  color: #4cc0e2;
  font-weight: 500;
  position: absolute;
  top: 20px;
  right: 80px;
  z-index: 999;
}

.icon {
  font-size: 20px;
  margin-right: 5px;
}

.hamburgerIcon {
  background-image: url('../assets/images/hamburger-icon.svg');
  background-repeat: no-repeat;
  width: 40px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 25px;
  z-index: 999;
  filter: invert(68%) sepia(17%) saturate(3690%) hue-rotate(167deg)
    brightness(109%) contrast(77%);
  cursor: pointer;
}

.hamburgerMenu {
  width: 160px;
  position: absolute;
  top: 65px;
  right: 20px;
  padding: 10px 15px;
  background-color: #eeeeee;
  z-index: 999;

  .row {
    border-bottom: 1px solid #cccccc;
    cursor: pointer;
  }
}

.sidebar_content {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}
