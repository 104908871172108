.root {
  padding: 40px;
}

.releaseGrid {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.markdownPreview {
  background: transparent;
  color: #656565;
  font-size: 14px;
}
