.actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.actionButton {
  background-color: #005282;
  color: white;
  height: 35px;
  border: 0;
  padding: 0 15px;

  &:disabled {
    opacity: 0.5;
    visibility: visible;
    cursor: not-allowed;
  }
}

.description {
  margin: 25px;
  text-align: center;
  font-size: 16px;
}
