.idleLogoffDialog {
  :global .k-dialog-content {
    display: flex;
    flex-direction: column;
  }
}

.sessionDesc {
  font-size: 14px;
}

.btnContinue {
  width: 200px;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.warning {
  padding: 20px 0 5px 0;
  text-align: center;
}
