.idleLogoffDialog {
  :global .k-dialog-content {
    display: flex;
    flex-direction: column;
  }
}

.sessionDesc {
  font-size: 14px;
}

.btnContinue {
  width: 120px;
  margin: 0 auto;
}
