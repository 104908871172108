.link,
.red-link {
  color: white;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  font-weight: bold;
}

.red-link {
  color: darkred;
}
