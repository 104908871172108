.main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div {
        flex-basis: 50%;
    }
}

.itemRow {
    padding-top: 10px;
}

.alignRight {
    text-align: right;
}