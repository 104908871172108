.dataGrid {
    cursor: pointer;
    min-height: 250px;
}

.infoBox {
    font-size: 12px;
    background-color: #ffffff;

}

.infoBoxWrap {
    font-size: 12px;
    background-color: #ffffff;
    white-space: nowrap;
}

.backgroundBox {
    font-size: 11px;
    background-color: #e2e1e1;
}

.backgroundWidth {
    font-size: 11px;
    background-color: #e2e1e1;
    width: 15%;
}

.backgroundWidth20 {
    font-size: 11px;
    background-color: #e2e1e1;
    width: 20%;
}

.backgroundWidthWrap {
    font-size: 11px;
    background-color: #e2e1e1;
    width: 15%;
    white-space: nowrap;
}