.drawerMenu {
  :global .k-drawer-items {
    padding: 10px 0;
  }
}

.drawerItem {
  font-size: 14px !important;
  padding: 8px 15px !important;
  margin: 0;
  border-bottom: 1px solid #ccc;

  &Icon {
    position: 'absolute';
    right: 10px;
  }
}
