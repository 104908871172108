.dataGrid {
    cursor: pointer;
    min-height: 250px;
}

.filterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;

    .filters {
        display: flex;
        align-items: stretch;
        gap: 20px;

        .multiSelectFilter,
        .inputFilter {
            width: 360px;
        }
    }

    .searchBtn {
        width: 120px;
    }

    .showAll {
        padding-left: 20px;
    }

    .getWorkItem {
        display: flex;
        align-self: flex-end;
    }

    .retrieveBtn {
        width: 200px;
        background-color: #005282;
        color: white;
        height: 35px;
        border: 0;
    }
}

.actions {
    text-align: center;
}

.link {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
    font-weight: bold;
}