.dataGrid {
  cursor: pointer;
  min-height: 250px;
}

.nextLink {
  padding-top: 10px;
}

.expandedLine {
  padding: 5px 0;
}
